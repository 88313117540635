import React from 'react';
import { logo, PhoneNumber, WhatsappCode } from '../config/config';  // Importing the named export 'logo'
import { FaWhatsapp } from "react-icons/fa";
import "slick-carousel/slick/slick.css"; // Slick Carousel styles
import "slick-carousel/slick/slick-theme.css"; // Optional, for theme styling



const MenuBar = () => {

    return (
        <div>
            <nav className='navbar navbar-expand-xl'>
                <div className='container-fluid '>
                    <a href='/' className='navbar-brand'>
                        <img className='logo' src={logo} alt="Logo" /> {/* Use 'logo' as the image source */}
                    </a>
                    <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#NavbarButton'>
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div className='navbar-collapse collapse' id='NavbarButton'>
                        <ul className='navbar-nav'>
                            <li className='nav-item'><a href={`tel:${PhoneNumber}`} className='nav-link'>{PhoneNumber}</a></li>
                            <li className='nav-item'><a href='/' className='nav-link'>Home</a></li>
                            <li className='nav-item'><a href='#Tariff' className='nav-link'>Tariff</a></li>
                            <li className='nav-item'><a href='/' className='nav-link'>About Us</a></li>
                            <li className='nav-item'><button className='btnStyle'> <a className='nav-link' href={`https://wa.me/${WhatsappCode}?text=`} target="_blank" rel="noopener noreferrer">
                                <span className='d-flex justify-content-center align-items-center gap-2'> <FaWhatsapp />Whatsapp</span>
                            </a></button></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default MenuBar;
