import React from 'react';
import { FaCar } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { LuMoonStar } from "react-icons/lu";
import EitosImg from '../Img/cars/Etios _ Equivalent.png'
import InnovaImg from '../Img/cars/Innova.png'
import InnovaCrystaImg from '../Img/cars/InnovaCrysta.png'
import SwiftImg from '../Img/cars/Swift, Zest, Xcent _ Equivalent.png'
import XyloImg from '../Img/cars/Xylo _ Equivalent.png'







const ChooseYourRideProps = [
    {
        title: 'Etios _ Equivalent',
        cardImg: EitosImg,
        OneWay: 'One Way Tariff - ₹14/km',
        RoundTrip: 'Round Trip Tariff - ₹13/km',
        carType: 'Sedan',
        MembersCount: '4+1',
    },
    {
        title: 'Xylo _ Equivalent',
        cardImg: XyloImg,
        OneWay: 'One Way Tariff - ₹19/km',
        RoundTrip: 'Round Trip Tariff - ₹18/km',
        carType: 'SUV',
        MembersCount: '6+1',
    },
    {
        title: 'Swift, Zest, Xcent _ Equivalent',
        cardImg: SwiftImg,
        OneWay: 'One Way Tariff - ₹14/km',
        RoundTrip: 'Round Trip Tariff - ₹13/km',
        carType: 'Hatchback',
        MembersCount: '4+1',
    },
    {
        title: 'Innova _ Equivalent',
        cardImg: InnovaImg,
        OneWay: 'One Way Tariff - ₹20/km',
        RoundTrip: 'Round Trip Tariff - ₹18/km',
        carType: 'Innova',
        MembersCount: '6+1',
    },
    {
        title: 'Innova Crysta _ Equivalent',
        cardImg: InnovaCrystaImg,
        OneWay: 'One Way Tariff - ₹25/km',
        RoundTrip: 'Round Trip Tariff - ₹23/km',
        carType: 'Innova Crysta',
        MembersCount: '7+1',
    },
];


const ChooseYourRide = () => {
    return (
        <div className='container' id='Tariff'>
            <h1 className='text-center'>Choose Your Ride</h1>
            <div className='row '> {/* Apply gap-3 to the row for spacing between cards */}
                {ChooseYourRideProps.map((item, index) => (
                    <div key={index} className='col-12 col-lg-4 gap-3'> {/* Each card takes up 4 columns on large screens */}
                        <div className='card p-3'>
                            <div className='discoImg'>
                                <img className='cardImg' src={item.cardImg} alt={item.title} />
                            </div>
                            <div className='card-body'>
                                <h3 className='card-title'>{item.title} </h3>
                                <p className='card-text'>{item.OneWay}</p>
                                <p className='card-text'>{item.RoundTrip}</p>
                                <div className='d-flex gap-3'>
                                    <p className='d-flex gap-2 align-items-center'>
                                        <FaCar /> {item.carType}
                                    </p>
                                    <p className='d-flex gap-2 align-items-center'>
                                        <IoMdPersonAdd /> {item.MembersCount}
                                    </p>
                                    <p className='d-flex gap-2 align-items-center'>
                                        <LuMoonStar /> AC
                                    </p>
                                </div>
                            </div>
                            <a href='#Form'>   <button className='CYRbtnStyle'>Book Now</button> </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default ChooseYourRide;
