import React from 'react'
import OutStationForm from './OutStationForm.js'
// import LocalRentalsForm from './LocalRentalsForm.js';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const Form = () => {
    const [activeButton, setActiveButton] = useState('outstation');

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };
    return (
        <div id='Form' className=' formMain homeform'>
            <div className='formmain '>
                <div className='rwe'>RIDE WITH EASE</div>
                <div className=' rounded-5 rounded-top-0'>
                    <OutStationForm />
                </div>
            </div>
        </div>
    )
}

export default Form