import MenuBarLogo from '../Img/logo.jpg';

const telegramid = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo';
const telegramChatId = '1113684027';
const WhatsappCode = '+91 8072782676';
const PhoneNumber = '+91 8072782676';
const WebsiteUrl = 'https://droptaxionetrip.com/';
const logo = MenuBarLogo;
const WebSiteName = 'Drop taxi one trip'
const WebsiteEmail = 'droptaxionetrip.com'

export { telegramid, telegramChatId, WhatsappCode, WebsiteUrl, PhoneNumber, logo, WebSiteName, WebsiteEmail };
