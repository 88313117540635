import './App.css';
import Banner from './Form/Banner'
import MenuBar from './Pages/MenuBar';
import Footer from './Pages/Footer';
import ChooseYourRide from './Pages/ChooseYourRide';
import Service from './Pages/Service';
import OurServices from './Pages/OurServices';
function App() {
  return (
    <div className="App">
      <MenuBar />

      <Banner />
      <ChooseYourRide />
      <Service />
      <OurServices />
      <Footer />
    </div>
  );
}

export default App;
