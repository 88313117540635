import React from 'react';
import OurServicesImg1 from '../Img/OurServices (3).jpeg'
import OurServicesImg2 from '../Img/OurServices (1).jpeg'
import OurServicesImg3 from '../Img/OurServices (2).jpeg'
import { WebSiteName } from '../config/config'

const OurServicesProps = [
    {
        title: 'Drop Taxi',
        description: 'Drop Taxi offers a convenient way to travel from one point to another without the hassle of driving or parking. Unlike typical outstation taxis that charge for both the trip to your destination and the return, even though you only want to complete your trip at the destination, Drop Taxi only charges for the one-way journey, that is, only pay for the distance that you have traveled.',
        cardImg: OurServicesImg1
    },
    {
        title: 'Local Rentals',
        description: '{WebSiteName} local rental services provide a convenient and flexible way to explore your city at your own pace. With a range of well-maintained vehicles and experienced drivers, you can enjoy a comfortable ride.',
        cardImg: OurServicesImg2
    },
    {
        title: 'Airport Transfers',
        description: '{WebSiteName} offers hassle-free and comfortable airport transfers with experienced drivers and well-maintained vehicles. We prioritize safety and punctuality to ensure that you reach your destination on time, every time.',
        cardImg: OurServicesImg3
    }
]

const OurServices = () => {
    return (
        <div>
            <div className='container'>
                <h1 className='text-center mt-5 mb-3'>Our Services</h1>
                <div className='row '> {/* Apply gap-3 to the row for spacing between cards */}
                    {OurServicesProps.map((service, index) => (
                        <div key={index} className='col-12 col-md-6 col-lg-4 gap-3 '> {/* Each card takes up 4 columns on large screens */}
                            <div className='card p-3'>
                                <img src={service.cardImg} alt={service.title} className="service-card-img" />
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>


            </div>


            <div className='container mt-3 pt-3'> {/* Each card takes up 4 columns on large screens */}
                <div className='card p-3 shadow'>
                    <h3>{WebSiteName}: The Best Choice for Drop Taxi Services in South India</h3>
                    <p>At {WebSiteName}, we are committed to providing efficient and reliable drop taxi services for our customers. We understand the importance of punctuality and convenience when it comes to drop taxi services, and that is why we strive to make every journey with us a hassle-free and comfortable experience. Our fleet of well-maintained vehicles and experienced drivers ensure that you reach your destination on time and in comfort. We offer transparent pricing with no hidden charges, and our payment options are flexible and convenient. We offer various drop taxi services, including one-way drop and round-trip options, to suit your requirements. Our customer support team is available 24/7 to assist you with your bookings and queries, and we ensure that your feedback and suggestions are taken into account to constantly improve our services. At {WebSiteName}, we prioritize the safety of our passengers and adhere to all safety protocols and guidelines. Our drivers are trained to follow traffic rules and maintain safe driving practices. We also ensure that our vehicles are regularly sanitized and equipped with safety features such as seat belts, airbags, and GPS tracking. Our customer support team is available 24/7 to assist you with your bookings and queries. You can easily book your cab online through our website or mobile app. We offer transparent pricing with no hidden charges and provide various payment options for your convenience. Choose</p>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
