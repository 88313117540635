// sendTelegramMessage.js
import React from 'react';
import axios from 'axios';
import { telegramChatId, telegramid, WhatsappCode, WebsiteUrl } from '../config/config';
import { useState } from 'react';

//One Way

const sendTelegramMessage = async (telegramid, telegramChatId, org_distance, org_duration, one_way_rate, driverBeta, data, localDateString) => {
    const telegramMessage =
        `\n\n Website Enquiry` +
        `\n\n ******* *******` +
        `\n\n Your Booking Details:` +
        `\n\n Name: ${data.firstName}` +
        `\n\n Phone Number: ${data.mobileNum}` +
        `\n\n Pick Up City: ${data.SelectCity}` +
        `\n\n Destination City: ${data.DestinationCity}` +
        `\n\n PickUp Date: ${localDateString}` +
        `\n\n PickUp Time: ${data.startTime}` +
        `\n\n Car Type: ${data.selectCarType}` +
        `\n\n Total Distance: ${org_distance}` +
        `\n\n Total Duration: ${org_duration}` +
        `\n\n Total Rate: ₹ ${one_way_rate} (Driver Beta Included)` +
        `\n\n Driver Beta: ₹ ${driverBeta}` +
        `\n\n Journey Trip: One Way Trip` +
        `\n\n Driver Allowance: INCLUDED` +
        `\n\n For Customer Intimation: Toll Gate, State Permit, Hill Station Charges Extra` +
        `\n\n Phone Number:  ${WhatsappCode}` +
        ` \n\n Website Url: ${WebsiteUrl}` +
        ` \n\nThanks`;

    try {
        const response = await axios.post(
            `https://api.telegram.org/bot${telegramid}/sendMessage`,
            {
                chat_id: telegramChatId,
                text: telegramMessage,
            }
        );
        return response.data; // Return the response if needed
    } catch (error) {
        console.error("Error sending Telegram message:", error);
        throw error; // Re-throw the error for handling in the calling function
    }
};

const sendWhatsappMessage = async (org_distance, org_duration, one_way_rate, driverBeta, formData, localDateString) => {

    const WhatsappMessage =
        `Website Enquiry%0A******* *******%0A 
        Your Booking Details:%0A%0A` +
        `Name: ${(formData.firstName)}%0A` +
        `Mobile Number: ${(formData.mobileNum)}%0A` +
        `Pickup City: ${(formData.SelectCity)}%0A` +
        `Destination City: ${(formData.DestinationCity)}%0A` +
        `Start Date: ${(localDateString)}%0A` +
        `Start Time: ${(formData.startTime)}%0A` +
        `Journey Trip: One Way Trip %0A` +
        `Total Distance: ${org_distance}%0A` +
        `Total Duration: ${org_duration}%0A` +
        `Total Rate: ₹ ${one_way_rate}%0A` +
        `Driver Beta: ₹ ${driverBeta}%0A` +
        `Driver Allowance: INCLUDED %0A` +
        `For Customer Intimation: Toll Gate, State Permit, Hill Station Charges Extra %0A` +
        `Website Url: ${WebsiteUrl} %0A` + `Phone Number:  ${WhatsappCode} %0A` + `Thanks`


    // Add your logic here to send the WhatsApp message, for example, using Twilio or another service
    console.log("WhatsApp Message Ready to Send:", WhatsappMessage);

    window.open(`https://wa.me/${WhatsappCode}?text=${WhatsappMessage}`);
};

const sendEmailMessage = () => {

    const sendEmail = async (org_distance, org_duration, one_way_rate, driverBeta, formData, localDateString) => {
        console.log(JSON.stringify(formData) + " log Form Data");


        const serviceId = "service_b4mj5jd";
        const templateId = "template_4we4gbf";
        const publicKey = "SHGkx9THx3qpzEFCp";


        const emailData = {
            service_id: serviceId,
            template_id: templateId,
            user_id: publicKey,
            template_params: {
                firstName: formData.firstName,
                mobileNum: formData.mobileNum,
                SelectCity: formData.SelectCity,
                DestinationCity: formData.DestinationCity,
                localDateString: localDateString,
                startTime: formData.startTime,
                org_distance: org_distance,
                org_duration: org_duration,
                one_way_rate: one_way_rate,
                driverBeta: driverBeta,
                WebsiteUrl: WebsiteUrl,
                WhatsappCode: WhatsappCode
            }

        };

        try {
            const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", emailData);
            console.log(emailData);

            console.log(res.data);
            // Clear input fields after sending
            return true; // Indicate success
        } catch (error) {
            console.error("Email send error:", error);
            throw new Error("Failed to send email."); // Re-throw for handling in the calling function
        }
    };
    return sendEmail;
}


//Round Trip
const sendTelegramMessageRT = async (telegramid, telegramChatId, org_distance, org_duration, RoundWayRate, roundeDriverBeta, data, localDateStringRT, localDateString) => {
    const telegramMessage =
        `\n\n Website Enquiry` +
        `\n\n ******* *******` +
        `\n\n Your Booking Details:` +
        `\n\n Name: ${data.firstName}` +
        `\n\n Phone Number: ${data.mobileNum}` +
        `\n\n Pick Up City: ${data.SelectCity}` +
        `\n\n Destination City: ${data.DestinationCity}` +
        `\n\n PickUp Date: ${localDateString}` +
        `\n\n PickUp Time: ${data.startTime}` +
        `\n\n Return Date: ${localDateStringRT}` +
        `\n\n Car Type: ${data.selectCarType}` +
        `\n\n Total Distance: ${org_distance}` +
        `\n\n Total Duration: ${org_duration}` +
        `\n\n Total Rate: ₹ ${RoundWayRate} (Driver Beta Included)` +
        `\n\n Driver Beta: ₹ ${roundeDriverBeta}` +
        `\n\n Journey Trip: Round Trip Trip` +
        `\n\n Driver Allowance: INCLUDED` +
        `\n\n For Customer Intimation: Toll Gate, State Permit, Hill Station Charges Extra` +
        `\n\n Phone Number:  ${WhatsappCode}` +
        ` \n\n Website Url: ${WebsiteUrl}` +
        ` \n\n Thanks`;
    try {
        const response = await axios.post(
            `https://api.telegram.org/bot${telegramid}/sendMessage`,
            {
                chat_id: telegramChatId,
                text: telegramMessage,
            }
        );
        return response.data; // Return the response if needed
    } catch (error) {
        console.error("Error sending Telegram message:", error);
        throw error; // Re-throw the error for handling in the calling function
    }
};


const sendWhatsappMessageRT = async (org_distance, org_duration, RoundWayRate, roundeDriverBeta, formData, localDateStringRT, localDateString) => {

    const WhatsappMessage =
        `Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0A` +
        `Name: ${(formData.firstName)}%0A` +
        `Mobile Number: ${(formData.mobileNum)}%0A` +
        `Pickup City: ${(formData.SelectCity)}%0A` +
        `Destination City: ${(formData.DestinationCity)}%0A` +
        `Start Date: ${(localDateString)}%0A` +
        `Return Date: ${(localDateStringRT)}%0A` +
        `Journey Trip: Round Trip %0A` +
        `Total Distance: ${org_distance}%0A` +
        `Total Duration: ${org_duration}%0A` +
        `Total Rate: ₹ ${RoundWayRate}%0A` +
        `Driver Beta: ₹ ${roundeDriverBeta}%0A` +
        `Driver Allowance: INCLUDED %0A` +
        `For Customer Intimation: Toll Gate, State Permit, Hill Station Charges Extra %0A` +
        `Website Url: ${WebsiteUrl} %0A` + `Phone Number:  ${WhatsappCode} %0A` + `Thanks`

    // Add your logic here to send the WhatsApp message, for example, using Twilio or another service
    console.log("WhatsApp Message Ready to Send:", WhatsappMessage);

    window.open(`https://wa.me/${WhatsappCode}?text=${WhatsappMessage}`);
};


const sendEmailMessageRT = () => {

    const sendEmail = async (org_distance, org_duration, RoundWayRate, roundeDriverBeta, formData, localDateStringRT, localDateString) => {
        console.log(JSON.stringify(formData) + " log Form Data");

        const serviceId = "service_b4mj5jd";
        const templateId = "template_8h0arwi";
        const publicKey = "SHGkx9THx3qpzEFCp";

        const emailData = {
            service_id: serviceId,
            template_id: templateId,
            user_id: publicKey,
            template_params: {
                firstName: formData.firstName,
                mobileNum: formData.mobileNum,
                SelectCity: formData.SelectCity,
                DestinationCity: formData.DestinationCity,
                localDateStringRT: localDateStringRT,
                localDateString: localDateString,
                startTime: formData.startTime,
                org_distance: org_distance,
                org_duration: org_duration,
                RoundWayRate: RoundWayRate,
                roundeDriverBeta: roundeDriverBeta,
                WebsiteUrl: WebsiteUrl,
                WhatsappCode: WhatsappCode
            }
        };

        try {
            const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", emailData);
            console.log(res.data); // Ensure the response is logged to see if email was sent successfully
            return true; // Indicate success
        } catch (error) {
            console.error("Email send error:", error);
            throw new Error("Failed to send email."); // Throw error to handle in the calling function
        }
    };

    return sendEmail;
};

export { sendTelegramMessage, sendWhatsappMessage, sendEmailMessage, sendTelegramMessageRT, sendWhatsappMessageRT, sendEmailMessageRT };
