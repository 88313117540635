import React from 'react'
import { logo, WebSiteName, WebsiteEmail } from "../config/config"
import { FaWhatsapp } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { WhatsappCode, PhoneNumber } from '../config/config';





const Footer = () => {
    return (
        <div>

            <div className='container mt-5' id='Contact'>
                <div className='row'>

                    <div className='col-12 col-lg-4'>
                        <a className='navbar-brand' href='/'>
                            <img className='logo' src={logo} />
                        </a>
                        <p className="text-justify">We are providing {WebSiteName}, Outstation Taxi, and Airport Taxi. Hire {WebSiteName} with cheapest price right at your place. Always Available 24/7.</p>
                    </div>
                    <div className='col-12 col-lg-4 text-justify'>
                        <ul><h3>Quick Links</h3>
                            <li className='footerMenu'><a href='/' aria-current="page">Home</a></li>
                            <li className='footerMenu'><a href='/'>About</a></li>
                            <li className='footerMenu'><a href='#Tariff'>Tariff</a></li>
                            <li className='footerMenu'><a href='#Contact'>Contact</a></li>
                        </ul>

                    </div>

                    <div className='col-12 col-lg-4 text-justify text-black'>
                        <h3>Official Info</h3>
                        <h5>Location:</h5>
                        <p>Tirunelveli, Tamil Nadu, India.</p>
                        <h5>Email: </h5>

                        <a href="mailto:droptaxionetrip.com" id="email_footer"><p>{WebsiteEmail}</p></a>
                        <h5>Phone:</h5>
                        <p><a href={`tel:${PhoneNumber}`} className='text-dark'>        {PhoneNumber}   </a> </p>
                    </div>
                </div>

            </div>

            {/* <div className='bgc-primary text-center text-white p-3 '>Developed By <a className='text-black fw-bold' href='https://netbixzie.in/' target='_blank'>Netbix Technologies</a></div> */}

            <div className='WhatsappNumber d-lg-block d-none'>
                <div class="whatsapp-btn">
                    <div class="whatsappIcon-btn">
                        <a href={`https://wa.me/${WhatsappCode}?text=`} target="_blank" rel="noopener noreferrer">   <FaWhatsapp /></a>
                    </div>
                </div>
            </div><div className='PhoneNumber d-lg-block d-none'>
                <div class="Phone-btn">
                    <div class="PhoneIcon-btn">
                        <a href={`tel:${PhoneNumber}`}><IoMdCall /></a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer