import React from 'react'
import { IoCall } from "react-icons/io5";
import Form from "../Form/Form"
import { WhatsappCode, PhoneNumber } from '../config/config';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; // Slick Carousel styles
import "slick-carousel/slick/slick-theme.css"; // Optional, for theme styling
import TrichyImg from '../Img/trichy.png'
import MaduraiImg from '../Img/Madurai.png'
import CoimbatoreImg from '../Img/Coimbatore.png'
import PondicherryImg from '../Img/pondicherry.png'
import BangaloreImg from '../Img/Bangalore.png'




export const CardComponent = ({ imgSrc, altText, link, title, duration, distance, price, currency, featured }) => {
    return (
        <li className="position-relative rounded-2xl p-2">
            {/* Absolute background style for the card */}
            <div aria-hidden="true" className="position-absolute top-0 start-0 z-index-n1 w-100 h-100 cursor-pointer "></div>

            {/* Card link */}
            <a className="position-relative z-index-2 d-flex flex-column align-items-center cardsub" href={link}>
                {/* Card Image */}
                <div className="position-relative w-100 overflow-hidden" style={{ borderRadius: "1rem" }}>
                    <img
                        src={imgSrc}
                        alt={altText}
                        className="bannerImg"
                    />

                    {/* Card Content */}
                    <div className="w-100 shadow bg-light p-2 mt-2 ps-lg-4" style={{ borderRadius: "1rem" }}>
                        {/* Featured Badge */}
                        {featured && (
                            <div className="d-flex justify-content-start mb-2">
                                <div className="badge bg-danger text-white text-uppercase p-1">Featured</div>
                            </div>
                        )}

                        {/* Title */}
                        <h4 className="d-none d-md-block text-left">{title}</h4>

                        {/* Info Grid */}
                        <div className="d-flex gap-3 text-dark justify-content-around">
                            <div className="d-flex flex-column align-items-start">
                                <div className="fw-bold">Duration</div>
                                <div>{duration}</div>
                            </div>
                            <div className="d-flex flex-column align-items-start">
                                <div className="fw-bold">Distance</div>
                                <div>{distance} km</div>
                            </div>
                            <div className="d-flex flex-column align-items-start">
                                <div className="fw-bold">Sedan</div>
                                <div>{currency} {price}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </a>
        </li>
    );
};



const Banner = () => {
    const settings = {
        dots: false, // Display dots navigation
        infinite: true, // Infinite loop, this is correct for looping behavior
        speed: 200, // Transition speed in ms
        autoplay: true, // Ensure autoplay is enabled if you want it to automatically change
        autoplaySpeed: 2000, // 2 seconds between auto-slide
        slidesToShow: 1.5, // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        responsive: [
            {
                breakpoint: 1024, // For screens >= 1024px (desktops)
                settings: {
                    slidesToShow: 1, // Shows 3 items per slide
                },
            },
            {
                breakpoint: 768, // For screens >= 768px (tablets)
                settings: {
                    slidesToShow: 1, // Shows 2 items per slide
                },
            },
            {
                breakpoint: 480, // For screens >= 480px (mobiles)
                settings: {
                    slidesToShow: 1, // Shows 1 item per slide
                },
            },
        ],
    };

    return (
        <div style={{ backgroundColor: 'white' }}>
            <div className='container-fluid pt-5 pb-5' id='Home' >
                <div className='row'>
                    <div className='col-12 col-lg-7'>
                        <div className='py-3 px-2 '>
                            {/* <img className='bannerImg' src={bannerImg} /> */}
                            <h1 className='fw-bold' >Book Drop Taxi</h1>
                            <h2 className='fw-bold disco-text'>@ Just ₹14/km</h2>
                            <p className='fs-3'>Book now, confirm later</p>
                            <p className='fs-4'>Book your outstation taxi now, pay only for one way!</p>


                            <Slider {...settings}>
                                <CardComponent
                                    imgSrc={TrichyImg} // Replace with actual image source
                                    altText="Madurai"
                                    // link="https://svdroptaxi.com/Chennai-to-Madurai-drop-taxi"
                                    title="Chennai to Trichy"
                                    duration="2 hr 26 min"
                                    distance="332"
                                    price="4648"
                                    currency="₹"
                                    featured={true}
                                />
                                {/* Add more CardComponents here as needed */}
                                <CardComponent
                                    imgSrc={CoimbatoreImg} // Replace with another image
                                    altText="Chennai to Coimbatore"
                                    // link="https://svdroptaxi.com/Chennai-to-Coimbatore-drop-taxi"
                                    title="Chennai to Coimbatore"
                                    duration="2 hr 26 min"
                                    distance="505"
                                    price="7070"
                                    currency="₹"
                                    featured={true}

                                />
                                <CardComponent
                                    imgSrc={PondicherryImg} // Replace with another image
                                    altText="Chennai to Pondicherry"
                                    //  link="https://svdroptaxi.com/Chennai-to-Pondicherry-drop-taxi"
                                    title="Chennai to Pondicherry"
                                    duration="2 hr 26 min"
                                    distance="150"
                                    price="2100"
                                    currency="₹"
                                    featured={true}
                                />
                                <CardComponent
                                    imgSrc={BangaloreImg} // Replace with another image
                                    altText="Chennai to Bangalore"
                                    //  link="https://svdroptaxi.com/Chennai-to-Bangalore-drop-taxi"
                                    title="Chennai to Bangalore"
                                    duration="5 hr 52 min"
                                    distance="346"
                                    price="4844"
                                    currency="₹"
                                    featured={true}
                                />
                                <CardComponent
                                    imgSrc={MaduraiImg} // Replace with another image
                                    altText="Chennai to Madurai"
                                    //  link="https://svdroptaxi.com/Chennai-to-Madurai-drop-taxi"
                                    title="Chennai to Madurai"
                                    duration="7 hr 6 min"
                                    distance="460"
                                    price="6440"
                                    currency="₹"
                                    featured={true}
                                />

                            </Slider>



                        </div>
                    </div>

                    <div className='col-12 col-lg-5 formMain formBo' >
                        <Form />
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Banner 