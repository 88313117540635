import React from 'react';

const Service = () => {
    return (
        <div className='container'>
            <h1 className='text-center mt-5 mb-3'>One Way Taxi Service in Cities</h1>
            <div className="row boxCYR">
                <div className="col-6 col-lg-3 p-3 ">
                    <div className="box">
                        <ul>
                            <li>Alandur</li>
                            <li>Ambattur</li>
                            <li>Ambur</li>
                            <li>Arani</li>
                            <li>Ariyalur</li>
                            <li>Avadi</li>
                            <li>Bangalore</li>
                            <li>Chengalpattu</li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-lg-3 p-3 ">
                    <div className="box">
                        <ul>
                            <li>Chennai</li>
                            <li>Chetpet</li>
                            <li>Cheyyar</li>
                            <li>Coimbatore</li>
                            <li>Cuddalore</li>
                            <li>Dharmapuri</li>
                            <li>Dindigul</li>
                            <li>Erode</li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-lg-3 p-3 ">
                    <div className="box">
                        <ul>
                            <li>Hosur</li>
                            <li>Kalambur</li>
                            <li>Kallakurichi</li>
                            <li>Kancheepuram</li>
                            <li>Kanchipuram</li>
                            <li>Kanyakumari</li>
                            <li>Karaikkudi</li>
                            <li>Karur</li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-lg-3 p-3 ">
                    <div className="box">
                        <ul>
                            <li>Krishnagiri</li>
                            <li>Kumbakonam</li>
                            <li>Kurichi</li>
                            <li>Madavaram</li>
                            <li>Madurai</li>
                            <li>Nagapattinam</li>
                            <li>Nagercoil</li>
                            <li>Namakkal</li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Service;
