import React from 'react'
import OutStationOneWay from './OutStationOneWay'
import OutStationRoundTrip from './OutStationRoundTrip'
import { useState } from 'react'

const OutStationForm = () => {
    const [formChange, setFormChange] = useState(false);
    const handleTripChange = (event) => {
        setFormChange(event.target.value === 'RoundTrip');
    };
    return (
        <div>
            <div className='d-flex justify-content-evenly formMain'>
                <div className=' col-lg-6 formtoggle'>
                    <label>
                        <input type='radio' className='radiocls' name='trip' value='oneway' onChange={handleTripChange} onClick={() => setFormChange(false)}
                            checked={!formChange} />
                        <span className="custom-radio"></span> One Way Trip
                    </label></div>

                <div className=' col-lg-6 formtoggle'>
                    <label>
                        <input type='radio' className='radiocls' name='trip' value='RoundTrip' onChange={handleTripChange} onClick={() => setFormChange(true)} checked={formChange} />
                        <span className="custom-radio"></span>Round Trip
                    </label>
                </div>
            </div>
            {!formChange ? <OutStationOneWay /> : <OutStationRoundTrip />}

        </div>

    )
}

export default OutStationForm